import isNotAuth from '@/router/middleware/auth/isNotAuth'
import auth from '@/router/middleware/auth/auth'
import checkWorkspace from '@/router/middleware/checkWorkspace'
import accountInReview from '@/router/middleware/auth/accountInReview'
import trialEnded from '@/router/middleware/auth/trialEnded'
import isOwner from '@/router/middleware/isOwner'
import isWorkspaceOwner from '@/router/middleware/isWorkspaceOwner'
import isNotReviewsTrialEnded from '@/router/middleware/auth/isNotReviewsTrialEnded'
import isNotEmptyWorkspace from '@/router/middleware/isNotEmptyWorkspace'
import isAccountDisabled from '@/router/middleware/auth/isAccountDisabled'
import notFound from '@/router/middleware/notFound'
import checkPermissions from '@/router/middleware/checkPermissions'

import { ROUTES } from '@/constants/routes'
import { PERMISSION_NAMES } from '@/constants/workspace/permissionSettings'

export const BASE_ROUTES = [
    {
        path: '/auth',
        name: ROUTES.auth,
        redirect: { name: 'AuthLogin' },
        component: () => import('@/views/auth/Auth.vue'),
        children: [{
            path: 'login',
            name: ROUTES.authLogin,
            meta: {
                layout: 'empty',
                middleware: isNotAuth,
                title: 'Dialics Login',
                reference: ROUTES.auth,
            },
            component: () => import('@/views/auth/login/AuthLogin.vue'),
        }, {
            path: 'reset-password',
            name: ROUTES.authReset,
            meta: { layout: 'empty', middleware: isNotAuth, reference: ROUTES.auth },
            component: () => import('@/views/auth/reset-password/ResetPassword.vue'),
        }, {
            path: 'reset-password/:token',
            name: ROUTES.authSetNewPassword,
            meta: {
                layout: 'empty',
                reference: ROUTES.auth,
            },
            component: () => import('@/views/set-new-password/SetNewPassword.vue'),
        }],
    }, {
        path: '/signup',
        name: ROUTES.register,
        meta: {
            layout: 'empty', middleware: isNotAuth, title: 'Dialics Signup', reference: ROUTES.register,
        },
        component: () => import('@/views/register/Register.vue'),
    }, {
        path: '/invite/:slug',
        name: ROUTES.inviteUser,
        meta: {
            layout: 'empty',
            reference: ROUTES.inviteUser,
        },
        component: () => import('@/views/invite-users/InviteUsers.vue'),
    }, {
        path: '/:workspace/reporting',
        name: ROUTES.reporting,
        meta: {
            layout: 'main',
            middleware: [auth, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.reporting,
        },
        component: () => import('@/views/call-tracking/reporting/Reporting.vue'),
    }, {
        path: '/:workspace/campaigns',
        name: ROUTES.manageCampaigns,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageCampaigns,
            permission: PERMISSION_NAMES.view_campaigns,
        },
        component: () => import('@/views/call-tracking/campaigns/ManageCampaigns.vue'),
    }, {
        path: '/:workspace/campaigns/:slug',
        name: ROUTES.detailCampaigns,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageCampaigns,
            permission: PERMISSION_NAMES.view_campaigns,
        },
        component: () => import('@/views/call-tracking/campaigns/DetailCampaign.vue'),
    }, {
        path: '/:workspace/tracking-numbers',
        name: ROUTES.manageNumbers,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageNumbers,
            permission: PERMISSION_NAMES.view_phone_numbers,
        },
        component: () => import('@/views/call-tracking/tracking-numbers/ManageTrackingNumbers.vue'),
    }, {
        path: '/:workspace/tracking-numbers/buy-tracking-numbers',
        name: ROUTES.buyNumbers,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageNumbers,
            permission: PERMISSION_NAMES.view_phone_numbers,
        },
        component: () => import('@/views/call-tracking/tracking-numbers/BuyTrackingNumbers.vue'),
    }, {
        path: '/:workspace/tracking-numbers/:slug',
        name: ROUTES.detailNumber,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageNumbers,
            permission: PERMISSION_NAMES.view_phone_numbers,
        },
        component: () => import('@/views/call-tracking/tracking-numbers/DetailTrackingNumber.vue'),
    }, {
        path: '/:workspace/pools',
        name: ROUTES.managePools,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.managePools,
            permission: PERMISSION_NAMES.view_phone_number_pools,
        },
        component: () => import('@/views/call-tracking/number-pools/ManageNumberPools.vue'),
    }, {
        path: '/:workspace/pools/:slug',
        name: ROUTES.detailPool,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.managePools,
            permission: PERMISSION_NAMES.view_phone_number_pools,
        },
        component: () => import('@/views/call-tracking/number-pools/DetailNumberPool.vue'),
    }, {
        path: '/:workspace/blocked-numbers',
        name: ROUTES.blockNumber,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.blockNumber,
            permission: PERMISSION_NAMES.view_blocked_numbers,
        },
        component: () => import('@/views/call-tracking/blocked-numbers/ManageBlockedNumbers.vue'),
    }, {
        path: '/:workspace/voip-shield',
        name: ROUTES.voipShield,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.voipShield,
            permission: PERMISSION_NAMES.view_voip_black_lists,
        },
        component: () => import('@/views/call-tracking/voip-shield/ManageVoipShield.vue'),
    }, {
        path: '/:workspace/voip-shield/:slug',
        name: ROUTES.detailVoipShield,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.voipShield,
            permission: PERMISSION_NAMES.view_voip_black_lists,
        },
        component: () => import('@/views/call-tracking/voip-shield/DetailVoipShield.vue'),
    }, {
        path: '/:workspace/destinations',
        name: ROUTES.manageDestinations,
        meta: {
            layout: 'main',
            middleware: [auth, checkWorkspace, accountInReview, trialEnded, checkPermissions],
            reference: ROUTES.manageDestinations,
            permission: PERMISSION_NAMES.view_destinations,
        },
        component: () => import('@/views/call-tracking/destinations/ManageDestinations.vue'),
    }, {
        path: '/:workspace/destinations/:slug',
        name: ROUTES.detailDestination,
        meta: {
            layout: 'main',
            middleware: [auth, checkWorkspace, accountInReview, trialEnded, checkPermissions],
            reference: ROUTES.manageDestinations,
            permission: PERMISSION_NAMES.view_destinations,
        },
        component: () => import('@/views/call-tracking/destinations/DetailDestination.vue'),
    }, {
        path: '/:workspace/destination-groups',
        name: ROUTES.manageDestinationGroups,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageDestinationGroups,
            permission: PERMISSION_NAMES.view_destination_groups,
        },
        component: () => import('@/views/call-tracking/destination-groups/ManageDestinationGroups.vue'),
    }, {
        path: '/:workspace/destination-groups/:slug',
        name: ROUTES.detailDestinationGroup,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageDestinationGroups,
            permission: PERMISSION_NAMES.view_destination_groups,
        },
        component: () => import('@/views/call-tracking/destination-groups/DetailDestinationGroup.vue'),
    }, {
        path: '/:workspace/ping-logs',
        name: ROUTES.pingPostLog,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.pingPostLog,
            permission: PERMISSION_NAMES.view_ping_log,
        },
        component: () => import('@/views/call-tracking/ping-log/ManagePingLog.vue'),
    }, {
        path: '/:workspace/buyers',
        name: ROUTES.manageBuyers,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageBuyers,
            permission: PERMISSION_NAMES.view_buyers,
        },
        component: () => import('@/views/call-tracking/buyers/ManageBuyers.vue'),
    }, {
        path: '/:workspace/buyers/:slug',
        name: ROUTES.detailBuyer,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageBuyers,
            permission: PERMISSION_NAMES.view_buyers,
        },
        component: () => import('@/views/call-tracking/buyers/DetailBuyer.vue'),
    }, {
        path: '/:workspace/vendors',
        name: ROUTES.manageVendors,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageVendors,
            permission: PERMISSION_NAMES.view_vendors,
        },
        component: () => import('@/views/call-tracking/vendors/ManageVendors.vue'),
    }, {
        path: '/:workspace/vendors/:slug',
        name: ROUTES.detailVendor,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageVendors,
            permission: PERMISSION_NAMES.view_vendors,
        },
        component: () => import('@/views/call-tracking/vendors/DetailVendor.vue'),
    }, {
        path: '/:workspace/call-flows',
        name: ROUTES.callFlows,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.callFlows,
            permission: PERMISSION_NAMES.view_call_flows,
        },
        component: () => import('@/views/call-tracking/call-flows/CallFlows.vue'),
    }, {
        path: '/:workspace/call-flows/:slug',
        name: ROUTES.detailCallFlows,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.callFlows,
            permission: PERMISSION_NAMES.view_call_flows,
        },
        component: () => import('@/views/call-tracking/call-flows/DetailCallFlows.vue'),
    }, {
        path: '/:workspace/traffic-sources',
        name: ROUTES.manageTrafficSources,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageTrafficSources,
            permission: PERMISSION_NAMES.view_traffic_sources,
        },
        component: () => import('@/views/call-tracking/traffic-sources/ManageTrafficSources.vue'),
    }, {
        path: '/:workspace/traffic-sources/:slug',
        name: ROUTES.detailTrafficSource,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageTrafficSources,
            permission: PERMISSION_NAMES.view_traffic_sources,
        },
        component: () => import('@/views/call-tracking/traffic-sources/DetailTrafficSource.vue'),
    }, {
        path: '/:workspace/integrations',
        name: ROUTES.manageIntegrations,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageIntegrations,
            permission: PERMISSION_NAMES.view_integrations,
        },
        component: () => import('@/views/call-tracking/integrations/manage-integrations/ManageIntegrations.vue'),
    }, {
        path: '/:workspace/manage-leads',
        name: ROUTES.manageLeads,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageLeads,
            permission: PERMISSION_NAMES.view_leads,
        },
        component: () => import('@/views/call-tracking/leads/ManageLeads.vue'),
    }, {
        path: '/:workspace/custom-parameters',
        name: ROUTES.customParameters,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.customParameters,
            permission: PERMISSION_NAMES.view_custom_parameters,
        },
        component: () => import('@/views/call-tracking/custom-parameters/CustomParameters.vue'),
    }, {
        path: '/:workspace/integration/google-ads',
        name: ROUTES.googleAdsIntegration,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageIntegrations,
            permission: PERMISSION_NAMES.view_integrations,
        },
        component: () => import('@/views/call-tracking/integrations/google-integration/GoogleIntegration.vue'),
    }, {
        path: '/:workspace/integration/facebook-ads',
        name: ROUTES.facebookAdsIntegration,
        meta: {
            layout: 'main',
            middleware: [auth, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageIntegrations,
        },
        component: () => import('@/views/call-tracking/integrations/facebook-integration/FacebookIntegration.vue'),
    }, {
        path: '/:workspace/integration/facebook-ads/traffic-sources/:slug',
        name: ROUTES.facebookAdsIntegrationTrafficSource,
        meta: {
            layout: 'main',
            middleware: [auth, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageIntegrations,
        },
        component: () => import('@/views/call-tracking/traffic-sources/DetailTrafficSource.vue'),
    }, {
        path: '/:workspace/integration/google-ads/traffic-sources/:slug',
        name: ROUTES.googleAdsIntegrationTrafficSource,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageIntegrations,
            permission: PERMISSION_NAMES.view_integrations,
        },
        component: () => import('@/views/call-tracking/traffic-sources/DetailTrafficSource.vue'),
    }, {
        path: '/:workspace/integration/incoming-webhooks',
        name: ROUTES.incomingWebhooksIntegration,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageIntegrations,
            permission: PERMISSION_NAMES.view_integrations,
        },
        component: () => import('@/views/call-tracking/integrations/incoming-webhooks-integration/IncomingWebhooksIntegration.vue'),
    }, {
        path: '/:workspace/integration/incoming-webhooks/:slug',
        name: ROUTES.detailIncomingWebhooksIntegration,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageIntegrations,
            permission: PERMISSION_NAMES.view_integrations,
        },
        component: () => import('@/views/call-tracking/integrations/incoming-webhooks-integration/DetailIncomingWebhookIntegration.vue'),
    }, {
        path: '/:workspace/integration/outgoing-webhooks',
        name: ROUTES.outgoingWebhooksIntegration,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageIntegrations,
            permission: PERMISSION_NAMES.view_integrations,
        },
        component: () => import('@/views/call-tracking/integrations/outgoing-webhooks-integration/OutgoingWebhooksIntegration.vue'),
    }, {
        path: '/:workspace/integration/outgoing-webhooks/:slug',
        name: ROUTES.detailOutgoingWebhooksIntegration,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageIntegrations,
            permission: PERMISSION_NAMES.view_integrations,
        },
        component: () => import('@/views/call-tracking/integrations/outgoing-webhooks-integration/DetailOutgoingWebhookIntegration.vue'),
    }, {
        path: '/:workspace/integration/outgoing-webhooks-log/:slug',
        name: ROUTES.detailOutgoingWebhooksLogIntegration,
        meta: {
            layout: 'main',
            middleware: [auth, checkPermissions, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageIntegrations,
            permission: PERMISSION_NAMES.view_integrations,
        },
        component: () => import('@/views/call-tracking/integrations/outgoing-webhooks-integration/DetailOutgoingWebhookLogIntegration.vue'),
    }, {
        path: '/:account/white-label',
        name: ROUTES.whiteLabel,
        meta: {
            layout: 'main',
            middleware: [auth, isOwner, checkWorkspace, trialEnded],
            reference: ROUTES.whiteLabel,
        },
        component: () => import('@/views/settings/account-setting/white-label/WhiteLabel.vue'),
    }, {
        path: '/:workspace/getting-start',
        name: ROUTES.gettingStart,
        meta: {
            layout: 'main',
            middleware: [auth, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.gettingStart,
        },
        component: () => import('@/views/getting-start/GettingStart.vue'),
    }, {
        path: '/:account/workspaces/:workspace',
        name: ROUTES.manageWorkspace,
        meta: {
            layout: 'main',
            middleware: [auth, isOwner, isWorkspaceOwner, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageWorkspace,
        },
        component: () => import('@/views/workspaces/ManageWorkspaces.vue'),
    }, {
        path: '/:account/workspaces/:workspace/roles/:slug',
        name: ROUTES.detailWorkspaceRole,
        meta: {
            layout: 'main',
            middleware: [auth, isOwner, isWorkspaceOwner, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageWorkspace,
        },
        component: () => import('@/views/workspaces/DetailWorkspaceRole.vue'),
    }, {
        path: '/:account/account-setting',
        name: ROUTES.accountSetting,
        meta: {
            layout: 'main',
            middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.accountSetting,
        },
        component: () => import('@/views/settings/account-setting/account/Account.vue'),
    }, {
        path: '/profile',
        name: ROUTES.userSetting,
        meta: {
            layout: 'main',
            middleware: [auth, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.userSetting,
        },
        component: () => import('@/views/settings/profile/Profile.vue'),
    }, {
        path: '/password',
        name: ROUTES.password,
        meta: {
            layout: 'main',
            middleware: [auth, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.password,
        },
        component: () => import('@/views/settings/security/password/Password.vue'),
    }, {
        path: '/:account/recharge-balance',
        name: ROUTES.rechargeBalance,
        meta: {
            layout: 'main',
            middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.rechargeBalance,
        },
        component: () => import('@/views/settings/billing/recharge-balance/RechargeBalance.vue'),
    }, {
        path: '/:account/billing-profile',
        name: ROUTES.billingProfile,
        meta: {
            layout: 'main',
            middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.billingProfile,
        },
        component: () => import('@/views/settings/billing/billing-profile/BillingProfile.vue'),
    }, {
        path: '/two-factor-auth',
        name: ROUTES.twoFactorAuth,
        meta: {
            layout: 'main',
            middleware: [auth, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.twoFactorAuth,
        },
        component: () => import('@/views/settings/security/two-factor-auth/TwoFactorAuth.vue'),
    }, {
        path: '/login-security',
        name: ROUTES.loginSecurity,
        meta: {
            layout: 'main',
            middleware: [auth, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.loginSecurity,
        },
        component: () => import('@/views/settings/security/login-security/LoginSecurity.vue'),
    }, {
        path: '/:account/payment-history',
        name: ROUTES.paymentHistory,
        meta: {
            layout: 'main',
            middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.paymentHistory,
        },
        component: () => import('@/views/settings/billing/payment-history/PaymentHistory.vue'),
    }, {
        path: '/:account/rates-and-expenses',
        name: ROUTES.ratesExpenses,
        meta: {
            layout: 'main',
            middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.ratesExpenses,
        },
        component: () => import('@/views/settings/billing/rates-and-expenses/RatesAndExpenses.vue'),
    }, {
        path: '/:account/api-access-tokens',
        name: ROUTES.apiAccessTokens,
        meta: {
            layout: 'main',
            middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.apiAccessTokens,
        },
        component: () => import('@/views/settings/security/api-access-token/ApiAccessTokens.vue'),
    }, {
        path: '/:account/protection',
        name: ROUTES.protection,
        meta: {
            layout: 'main',
            middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.protection,
        },
        component: () => import('@/views/settings/security/protection/Protection.vue'),
    }, {
        path: '/:workspace/verification',
        name: ROUTES.verification,
        meta: {
            layout: 'main',
            middleware: [auth, isOwner, isNotReviewsTrialEnded],
            reference: ROUTES.verification,
        },
        component: () => import('@/views/verification/VerificationPage.vue'),
    }, {
        path: '/:account/manage-components',
        name: ROUTES.manageCards,
        meta: {
            layout: 'main',
            middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.manageCards,
        },
        component: () => import('@/views/settings/billing/manage-cards/ManageCards.vue'),
    }, {
        path: '/:workspace/workspace-activity',
        name: ROUTES.workspaceActivity,
        meta: {
            layout: 'main',
            middleware: [auth, isOwner, checkWorkspace, accountInReview, trialEnded],
            reference: ROUTES.workspaceActivity,
        },
        component: () => import('@/views/settings/security/workspace-activity/ManageWorkspaceActivity.vue'),
    }, {
        path: '/not-found-workspace',
        name: ROUTES.notFoundWorkspace,
        meta: {
            layout: 'workspace',
            middleware: [auth, accountInReview, trialEnded, isNotEmptyWorkspace],
            reference: ROUTES.notFoundWorkspace,
        },
        component: () => import('@/views/NotFoundWorkspace.vue'),
    }, {
        path: '/account-disabled',
        name: ROUTES.accountDisabled,
        meta: {
            layout: 'empty',
            middleware: [auth, accountInReview, trialEnded, checkWorkspace, isAccountDisabled],
            reference: ROUTES.accountDisabled,
        },
        component: () => import('@/views/account-disabled/AccountDisabled.vue'),
    }, {
        path: '/:pathMatch(.*)*',
        name: ROUTES.notFound,
        meta: {
            layout: 'empty',
            middleware: notFound,
            reference: ROUTES.notFound,
        },
        component: () => import('@/views/NotFound.vue'),
    },
]
