<template>
    <div class="relative w-full">
        <div class="flex items-center gap-x-2">
            <FileTwoMiniIcon class="text-gray-200" />

            <div class="text-gray-370 dark:text-white text-xs font-medium">
                {{ title }}
            </div>
        </div>

        <div class="max-mob:pt-2 pt-3 text-gray-180 dark:text-gray-190 text-xs">
            {{ processText }}
        </div>

        <component
            :is="currentIcon"
            class="absolute right-0 top-0 text-gray-370 dark:text-white"
        />
    </div>
</template>

<script setup>
import { computed, markRaw } from 'vue'

import AnimationDownloadIcon from '@/components/icons/download/AnimationDownloadIcon.vue'
import FailDownloadIcon from '@/components/icons/download/FailDownloadIcon.vue'
import SuccessDownloadIcon from '@/components/icons/download/SuccessDownloadIcon.vue'
import FileTwoMiniIcon from '@/components/icons/FileTwoMiniIcon.vue'

const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    size: {
        type: [String, Number],
        default: 0,
    },
    status: {
        type: [String, null],
        default: null,
    },
    statuses: {
        type: Object,
        default: () => ({}),
    },
})

const prepareSize = computed(() => (Math.ceil(props.size * 100)) / 100)

const currentIcon = computed(() => {
    switch (props.status) {
    case props.statuses.failed:
        return markRaw(FailDownloadIcon)
    case props.statuses.success:
        return markRaw(SuccessDownloadIcon)
    default:
        return markRaw(AnimationDownloadIcon)
    }
})

const processText = computed(() => {
    switch (props.status) {
    case props.statuses.failed:
        return 'Failed'
    case props.statuses.success:
        return 'Downloaded'
    default:
        return (props.size ? `Downloading: ${prepareSize.value}MB` : 'Preparing data for export...')
    }
})
</script>
