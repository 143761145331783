<template>
    <div
        v-show="showDownloadList"
        ref="draggableDownloadRef"
        class="z-100 fixed max-mob:w-full touch-none cursor-move max-mob:px-4"
        :style="currentStyles.style"
        @click.stop.prevent
    >
        <div class="w-full min-mob:w-101 bg-white dark:bg-blue-840 border border-gray-100 dark:border-blue-720 rounded-1.25">
            <div class="max-mob:p-3 p-4 flex justify-between items-center border-b border-gray-100 dark:border-blue-720">
                <div class="text-sm font-medium text-gray-370 dark:text-white">
                    Downloads
                </div>

                <CloseIconMini
                    class="text-gray-200 cursor-pointer"
                    @click="hidePopup"
                />
            </div>

            <div class="max-mob:p-3 p-4">
                <DownloadItem
                    v-if="downloadStore.downloadingAggregateStatus"
                    :title="downloadStore.aggregateFilename"
                    :size="downloadStore.aggregateSize"
                    :status="downloadStore.downloadingAggregateStatus"
                    :statuses="downloadStore.statuses"
                />

                <CustomDivider
                    v-if="showDivider"
                    class="max-mob:my-3 my-4"
                    color="border-white-500 dark:border-gray-760"
                />

                <DownloadItem
                    v-if="downloadStore.downloadingLogStatus"
                    :title="getLogFilename"
                    :size="downloadStore.logSize"
                    :status="downloadStore.downloadingLogStatus"
                    :statuses="downloadStore.statuses"
                />
            </div>
        </div>
    </div>

    <div
        v-show="showDownloadMobile"
        class="max-mob:flex hidden z-100 fixed left-4 bottom-4 w-14 h-14 bg-white dark:bg-blue-840 border border-gray-100 dark:border-blue-720 rounded-custom justify-center items-center"
        @click="downloadStore.changeShowPopup"
    >
        <component
            :is="currentIcon"
            class="text-gray-370 dark:text-white"
        />
    </div>
</template>

<script setup>
import {
    ref, computed, inject, watch, markRaw,
} from 'vue'
import { useDraggable, useElementSize } from '@vueuse/core'

import DownloadItem from '@/components/download/DownloadItem.vue'
import CustomDivider from '@/components/divider/CustomDivider.vue'
import CloseIconMini from '@/components/icons/CloseIconMini.vue'
import FailDownloadIcon from '@/components/icons/download/FailDownloadIcon.vue'
import AnimationDownloadIcon from '@/components/icons/download/AnimationDownloadIcon.vue'
import SuccessDownloadIcon from '@/components/icons/download/SuccessDownloadIcon.vue'

import { useDownloadStore } from '@/store/downloadReportStore'

import { viewPorts } from '@/constants/viewPorts'

defineProps({
    number: {
        type: String,
        default: '',
    },
    record: {
        type: String,
        default: '',
    },
    currentViewSize: {
        type: Number,
        default: 0,
    },
})

const downloadStore = useDownloadStore()

const currentViewSize = inject('currentViewSize')
const currentWidth = inject('currentWidth')

const draggableDownloadRef = ref(null)

const currentStyles = ref(null)

const { height } = useElementSize(draggableDownloadRef)

const isMobView = computed(() => viewPorts.mob >= currentViewSize.value)

const showDownloadList = computed(() => (downloadStore.downloadingAggregateStatus || downloadStore.downloadingLogStatus) && downloadStore.showPopup)
const showDownloadMobile = computed(() => (downloadStore.downloadingAggregateStatus || downloadStore.downloadingLogStatus) && !downloadStore.showPopup)

const showDivider = computed(() => downloadStore.downloadingAggregateStatus && downloadStore.downloadingLogStatus)

const someFailed = computed(() => downloadStore.downloadingLogStatus === downloadStore.statuses.failed || downloadStore.downloadingAggregateStatus === downloadStore.statuses.failed)
const someInProgress = computed(() => downloadStore.downloadingLogStatus === downloadStore.statuses.inProgress || downloadStore.downloadingAggregateStatus === downloadStore.statuses.inProgress)

const currentIcon = computed(() => {
    switch (true) {
    case someFailed.value:
        return markRaw(FailDownloadIcon)
    case someInProgress.value:
        return markRaw(AnimationDownloadIcon)
    default:
        return markRaw(SuccessDownloadIcon)
    }
})

const getLogFilename = computed(() => {
    if (downloadStore.logFilename) {
        return downloadStore.logFilename
    }

    if (downloadStore.downloadingLogStatus) {
        return 'call-log-export.xlsx'
    }
})

function setStyles() {
    currentStyles.value = useDraggable(draggableDownloadRef, {
        initialValue: isMobView.value
            ? { x: 0, y: window.innerHeight - height.value - 24 }
            : { x: window.innerWidth - 424, y: 75 },
    })
}

function hidePopup() {
    downloadStore.changeShowPopup()
}

watch(() => currentWidth.value, () => {
    setStyles()
}, { immediate: true })

watch(() => height.value, () => {
    setStyles()
}, { immediate: true })
</script>
