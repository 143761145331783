import { defineStore } from 'pinia'

import { getActiveColumnsHeader, getActiveHeadersTable } from '@/helpers/settings-header-table-helper'
import { checkEqualsFilter, createFiltersForSend, localFilterItems } from '@/helpers/filters-sorting-header-table-helper'
import { getClearQueryLocalStorage } from '@/helpers/remove-query-local-storage-helper'
import { getFilterLocalStorageByWorkspaceId, setFilterInLocalStorageByWorkspaceId } from '@/helpers/save-filter-local-storage-helper'

import { useDetailCampaignStore } from '@/store/call-tracking/campaigns/detailCampaignStore'
import { useSortFilterTableStore } from '@/store/sortFilterTableStore'
import { usePermissionsStore } from '@/store/permissionsStore'

import { LOCAL_FILTER_SHORT_NAME } from '@/constants/localFilterShortName'
import { SETTINGS_HEADER_TABLE } from '@/constants/headersTable/settingsHeaderTable'
import { FILTER_RULES } from '@/constants/filterRules'
import { viewPorts } from '@/constants/viewPorts'
import { FILTER_STATUS, FILTER_TYPE_NUMBER } from '@/constants/localFilters'
import { CAMPAIGN_TRACKING_NUMBERS_HEADERS_TABLE, CAMPAIGN_TRACKING_NUMBERS_HEADERS_TABLE_WITH_ACTIONS } from '@/constants/headersTable/campaignTrackingNumbersHeadersTable'
import { TYPE_NUMBER } from '@/constants/call-tracking/campaign/typeNumber'
import { NUMBERABLE_TYPES } from '@/constants/numberableTypes'

export const useDetailCampaignTrackingNumberTableStore = defineStore('detailCampaignTrackingNumberTable', {
    state: () => ({
        numbers: [],
        numberPools: [],

        vendors: [],

        tableFilters: [
            FILTER_STATUS,
            FILTER_TYPE_NUMBER,
        ],
        tableSorting: [],
        tableSelectedFilters: [],
        tableFiltersForSend: {},

        visibleHeadersTrackingNumbersTable: [],
        activeColumnsTrackingNumbersTable: [],
    }),
    getters: {
        tableControlParams(state) {
            return {
                filters: state.tableFilters.map((filter) => ({
                    ...filter,
                    rule: null,
                    value: null,
                })),
                sorting: [],
            }
        },

        attachedVendors(state) {
            return state.vendors
        },

        vendorsWithoutDuplicate({ numbers, numberPools }) {
            const items = [...numbers, ...numberPools]
                .filter((item) => item.vendor)
                .map((item) => item.vendor)

            return items.filter((vendor, index, vendors) => index === vendors.findIndex((v) => v.slug === vendor.slug))
        },

        localItems(state) {
            return localFilterItems([...state.numbers, ...state.numberPools], state.tableFiltersForSend)
        },
    },
    actions: {
        resetSortFilter() {
            this.tableSelectedFilters = []
            this.tableFiltersForSend = {}

            this.saveFiltersSortingInLocalStorage()
        },

        saveFiltersSortingInLocalStorage() {
            const detailCampaignStore = useDetailCampaignStore()

            setFilterInLocalStorageByWorkspaceId(this.tableFiltersForSend, `${LOCAL_FILTER_SHORT_NAME[SETTINGS_HEADER_TABLE.campaignTrackingNumber]}.${detailCampaignStore.id}`)
        },

        updateTrackingNumberStatus(payload) {
            payload.forEach((number) => {
                const index = this.numbers.findIndex((n) => n.slug === number.slug)
                if (index + 1) {
                    this.numbers[index].status_id = number.status_id
                }
            })
        },

        updateNumberPoolStatus(payload) {
            payload.forEach((numberPool) => {
                const index = this.numberPools.findIndex((n) => n.slug === numberPool.slug)

                if (index + 1) {
                    this.numberPools[index].status_id = numberPool.status_id
                }
            })
        },

        updateFilterAfterQueryLocalStorage(savedFilter) {
            const sortFilterTableStore = useSortFilterTableStore()

            const filters = []

            Object.keys(savedFilter).forEach((key) => {
                const findItem = this.tableFilters.find((el) => el.key === key)

                if (!findItem) {
                    return
                }

                const newItem = {
                    ...findItem,
                    value: savedFilter[key].value,
                    rule: FILTER_RULES[findItem.type].find((rule) => rule.key === savedFilter[key].rule),
                }

                filters.push(newItem)
            })

            if (!filters.length) {
                return
            }

            this.tableSelectedFilters = filters

            if (viewPorts.mob <= window.innerWidth) {
                sortFilterTableStore.changeIndexOpen(SETTINGS_HEADER_TABLE.campaignTrackingNumber, -2)
                sortFilterTableStore.openCloseFilter(SETTINGS_HEADER_TABLE.campaignTrackingNumber)
            }
        },

        getLocalStorageHeadersTable() {
            this.activeColumnsTrackingNumbersTable = getActiveColumnsHeader(this.getHeadersByPermissions(), SETTINGS_HEADER_TABLE.campaignTrackingNumber)
            this.setVisibleTrackingNumbersHeaders()
        },

        getQueryLocalStorage() {
            this.getLocalStorageHeadersTable()

            const value = getClearQueryLocalStorage()

            if (value) {
                this.getLocalStorageFilter()
                this.updateFilterAfterQueryLocalStorage(this.tableFiltersForSend)
            }
        },

        getLocalStorageFilter() {
            const detailCampaignStore = useDetailCampaignStore()
            const savedFilter = getFilterLocalStorageByWorkspaceId(`${LOCAL_FILTER_SHORT_NAME[SETTINGS_HEADER_TABLE.campaignTrackingNumber]}.${detailCampaignStore.id}`)

            if (!savedFilter && !Object.keys(savedFilter).length) {
                return
            }

            this.tableFiltersForSend = savedFilter
        },

        setVendors(vendors) {
            this.vendors = vendors
        },

        setFiltersSorting() {
            this.preparationFiltersForSet()
            this.saveFiltersSortingInLocalStorage()
        },

        preparationFiltersForSet() {
            const newFilter = createFiltersForSend(this.tableSelectedFilters, true)

            if (!checkEqualsFilter(newFilter, this.tableFiltersForSend)) {
                this.tableFiltersForSend = newFilter
            }
        },

        updateSelectedFilters(payload) {
            this.tableSelectedFilters = payload

            this.setFiltersSorting()
        },

        setVisibleTrackingNumbersHeaders() {
            this.visibleHeadersTrackingNumbersTable = getActiveHeadersTable(this.getHeadersByPermissions(), this.activeColumnsTrackingNumbersTable)
        },

        getHeadersByPermissions() {
            const permissionsStore = usePermissionsStore()

            if (permissionsStore.permissions.campaigns.edit || permissionsStore.permissions.numbers.view || permissionsStore.permissions.numberPools.view) {
                return CAMPAIGN_TRACKING_NUMBERS_HEADERS_TABLE_WITH_ACTIONS
            }

            return CAMPAIGN_TRACKING_NUMBERS_HEADERS_TABLE
        },

        changeTrackingNumbersHeaders(payload) {
            this.activeColumnsTrackingNumbersTable = payload
            this.setVisibleTrackingNumbersHeaders()
        },

        checkVendorForNumbers(number, vendor) {
            return number.vendor && number.vendor.slug === vendor.slug && vendor.pivot.numberable_id === number.id && vendor.pivot.numberable_type === NUMBERABLE_TYPES.STATIC_NUMBER
        },

        checkVendorForNumberPool(numberPool, vendor) {
            return numberPool.vendor && numberPool.vendor.slug === vendor.slug && vendor.pivot.numberable_id === numberPool.id && vendor.pivot.numberable_type === NUMBERABLE_TYPES.DYNAMIC_NUMBER
        },

        parseNumbers({ numbers }) {
            return numbers.map((number) => ({
                id: number.id,
                slug: number.slug,
                number: number.number,
                name: number.name,
                facebook_ads_event_id: number.facebook_ads_event_id || null,
                vendor_id: number.vendor_id,
                vendor: this.vendors.find((vendor) => this.checkVendorForNumbers(number, vendor)),
                type: TYPE_NUMBER.STATIC,
                status_id: number.status_id,
                lifetime_exceeded: number.lifetime_exceeded,
                cap_enabled: number.cap_enabled,
                max_concurrency_enabled: number.max_concurrency_enabled,
                max_concurrency: number.max_concurrency,
                cap_on_id: number.cap_on_id,
                live: number.live,
                hourly: number.hourly,
                hourly_cap: number.hourly_cap,
                daily: number.daily,
                daily_cap: number.daily_cap,
                monthly: number.monthly,
                monthly_cap: number.monthly_cap,
                global_cap: number.global_cap,
                global_revenue_cap: number.global_revenue_cap,
                traffic_source_id: number.traffic_source_id,
                traffic_source: number.traffic_source,
                conversion: number.conversion,
                conversion_id: number.conversion_id,
            }))
        },

        parseNumberPools({ phone_number_pools = [] }) {
            return phone_number_pools.map((numberPool) => ({
                id: numberPool.id,
                slug: numberPool.slug,
                number: 'Number Pool',
                name: numberPool.name,
                vendor_id: numberPool.vendor_id,
                vendor: this.vendors.find((vendor) => this.checkVendorForNumberPool(numberPool, vendor)),
                type: TYPE_NUMBER.DYNAMIC,
                status_id: numberPool.status_id,
                cap_enabled: null,
                cap_on_id: null,
                max_concurrency_enabled: null,
                max_concurrency: null,
                live: null,
                hourly: null,
                hourly_cap: null,
                daily: null,
                daily_cap: null,
                monthly: null,
                monthly_cap: null,
                global_cap: null,
                global_revenue_cap: null,
                conversion: null,
                conversion_id: null,
                facebook_ads_event_id: null,
            }))
        },

        setNumbers(numbers) {
            this.numbers = numbers
        },

        setNumberPools(numberPools) {
            this.numberPools = numberPools
        },

        addNumbers(numbers) {
            this.numbers = [...this.numbers, ...numbers]
        },

        addNumberPools(numberPools) {
            this.numberPools = [...this.numberPools, ...numberPools]
        },

        _removeNumberPoolsAfterDelete(numberPoolSlugs) {
            this.numberPools = this.numberPools.filter((numberPool) => !numberPoolSlugs.includes(numberPool.slug))
        },

        _removeTrackingNumbersAfterDelete(numbers) {
            this.numbers = this.numbers.filter((number) => !numbers.includes(number.slug))
        },
    },
})
