<template>
    <div
        v-if="showIcon"
        class="max-mob:hidden w-8 h-8 rounded-custom flex justify-center items-center hover:bg-purple-70 dark:hover:bg-blue-770 cursor-pointer transition-colors"
        :class="downloadStore.showPopup ? 'bg-gray-80 dark:bg-purple-700' : 'bg-transparent'"
        @click="downloadStore.changeShowPopup"
    >
        <component
            :is="currentIcon"
            class="text-gray-370 dark:text-white"
        />
    </div>
</template>

<script setup>
import { computed, markRaw } from 'vue'

import AnimationDownloadIcon from '@/components/icons/download/AnimationDownloadIcon.vue'
import FailDownloadIcon from '@/components/icons/download/FailDownloadIcon.vue'
import SuccessDownloadIcon from '@/components/icons/download/SuccessDownloadIcon.vue'

import { useDownloadStore } from '@/store/downloadReportStore'

const downloadStore = useDownloadStore()

const showIcon = computed(() => downloadStore.downloadingLogStatus || downloadStore.downloadingAggregateStatus)

const someFailed = computed(() => downloadStore.downloadingLogStatus === downloadStore.statuses.failed || downloadStore.downloadingAggregateStatus === downloadStore.statuses.failed)
const someInProgress = computed(() => downloadStore.downloadingLogStatus === downloadStore.statuses.inProgress || downloadStore.downloadingAggregateStatus === downloadStore.statuses.inProgress)

const currentIcon = computed(() => {
    switch (true) {
    case someFailed.value:
        return markRaw(FailDownloadIcon)
    case someInProgress.value:
        return markRaw(AnimationDownloadIcon)
    default:
        return markRaw(SuccessDownloadIcon)
    }
})
</script>
