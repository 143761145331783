const converted = { name: 'Call Connected', id: 1 }
const length = { name: 'Call Length', id: 2 }
const incoming = { name: 'Incoming Call', id: 3 }

const CONVERSIONS_OPTIONS = {
    converted,
    length,
    incoming,
    webhooks: { name: 'Webhook', beta: true, id: 4 },
}

const RTB_CONVERSIONS_OPTIONS = {
    converted,
    length,
    incoming,
}

export {
    CONVERSIONS_OPTIONS,
    RTB_CONVERSIONS_OPTIONS,
}
