const DETAIL_NUMBER_POOL_TRAFFIC_SOURCES_HEADERS_TABLE = [
    {
        name: 'Name',
        value: 'name',
        filterSettings: {},
        disabled: true,
    },
    {
        name: 'Integration',
        value: 'integration',
    },
    {
        name: 'Events',
        value: 'event',
        width: 200,
        align: 'center',
        filterSettings: {},
    },
    {
        name: 'Conversion',
        value: 'conversion',
        withoutPadding: true,
    },
]

const DETAIL_NUMBER_POOL_TRAFFIC_SOURCES_HEADERS_TABLE_WITH_ACTIONS = [
    ...DETAIL_NUMBER_POOL_TRAFFIC_SOURCES_HEADERS_TABLE,
    {
        name: 'Actions',
        value: 'actions',
        width: 114,
        align: 'center',
        disabled: true,
    },
]

export {
    DETAIL_NUMBER_POOL_TRAFFIC_SOURCES_HEADERS_TABLE,
    DETAIL_NUMBER_POOL_TRAFFIC_SOURCES_HEADERS_TABLE_WITH_ACTIONS,
}
